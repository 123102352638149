import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, message, Row, Select,} from "antd";
import {BASE_URL} from "../utils";
import LoadForm from "../utils/LoadForm";

const FormReader = () => {
    const [applications, setApplications] = useState([]);
    const [formData, setFormData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(applications.length > 0 ? applications[0]._id : null);
    const [formDataId, setFormDataId] = useState(formData.length > 0 ? formData[0]._id : null);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchData();

        return (() => {
            setApplications([]);
            setFormData([]);

        })
    }, []);

    useEffect(() => {
        if (applications.length > 0) {
            fetchApplicationForm(applications[0]._id);
            setSelectedValue(applications[0]._id);
        }
    }, [applications]);

    const fetchData = async () => {
        try {
            const res = await fetch(`${BASE_URL}applications`);
            const data = await res.json();
            if (!data.applications.length) {
                message.info("No applications found!");
                return;
            }
            setApplications(data.applications);
        } catch (error) {
            message.error("Something went wrong!");
        }
    };

    const fetchApplicationForm = async (applicationId) => {
        try {
            const res = await fetch(`${BASE_URL}form-builders/${applicationId}`);
            const data = await res.json();
            if (!data.form.length) {
                message.info("No form found for this application!");
                return;
            }
            setFormData(data.form || []);
            setFormDataId(data.form[0]._id);
        } catch (error) {
            message.error("Something went wrong!");
        }
    };

    const handleApplicationChange = async (value) => {
        await fetchApplicationForm(value);
    };

    const handleFormSubmit = async (values) => {
        try {
            const applicationId = values.applicationId;
            const formId = formDataId;


            const formValues = Object.entries(values)
                .filter(([key]) => key !== 'applicationId')
                .reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});

            const payload = {
                applicationId,
                formId,
                values: formValues,
            }

            const res = await fetch(`${BASE_URL}forms`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
            const data = await res.json();

            if (data.status) {
                message.success("Form submitted successfully!");
            } else {
                message.error("Something went wrong!");
            }

        } catch (error) {
            message.error("Something went wrong!");
        } finally {
            form.resetFields();
        }
    };

    if (applications.length === 0) {
        return <p>No data to display</p>;
    }

    return (
        <Row>
            <Col span={12} offset={6}>
                <Card
                    title="Form Reader"
                    bordered
                    hoverable
                    style={{marginTop: "20px"}}
                    cover
                >
                    <Form
                        onFinish={handleFormSubmit}
                        layout='vertical'
                        form={form}
                    >
                        <Form.Item
                            label="Select Application"
                            name="applicationId"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select an application",
                                },
                            ]}
                        >
                            <Select value={selectedValue} onChange={handleApplicationChange}>
                                {applications.map((item) => (
                                    <Select.Option key={item._id} value={item._id}>
                                        {item.applicationName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <LoadForm formData={formData}/>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );

};

export default FormReader;