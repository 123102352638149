import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../utils';
import { message, Spin, Button, Form, Input, Card } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { LockOutlined, MailOutlined, RedEnvelopeOutlined, UserOutlined } from '@ant-design/icons';

const Signup = () => {
    const nav = useNavigate();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);

        try {
            // Add user to database
            const response = await axios.post(`${BASE_URL}user`, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                role: 'applicant',
                applicationId: values.applicationID,
            });

            if (response.data.error) {
                setLoading(false);
                message.error("Something went wrong: ", response.data.error);
                setLoading(false)
                nav('/user/signup')
                return;
            }

            setLoading(false);
            nav('/');
        } catch (error) {
            setLoading(false);
            message.error('Failed to create user');
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const divStyle = {
        margin: 'auto',
        width: '50%',
        padding: '10px',
        marginTop: '100px',
    };

    return (
        <div style={divStyle}>
            <Card
                title="Sign Up"
                style={{ backgroundColor: 'lightgray', width: '100%' }}
                extra={
                    <Button type="link" block>
                        <Link to="/">Login</Link>
                    </Button>
                }
                cover={<img alt='logo' src='/fav.png' style={{marginLeft: '35%' , width: "30%", height: "150px"}}/>}
            >
                <Spin spinning={loading}>
                    <Form
                        name="basic"
                        initialValues={{
                            remember: false,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your first name',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} />
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your last name',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} />
                        </Form.Item>

                        <Form.Item
                            label="Application ID"
                            name="applicationID"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your application ID',
                                },
                            ]}
                        >
                            <Input prefix={<RedEnvelopeOutlined />} />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your email',
                                },
                            ]}
                        >
                            <Input prefix={<MailOutlined />} />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your password',
                                },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="MainButtons" block>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Card>
        </div>
    );
};

export default Signup;
