import React from "react";
import { DatePicker, Form, Input, Radio, Select } from "antd";

const LoadForm = ({ formData }) => {
    return (
        <>
            {formData.length > 0 ? (
                formData[0].fields.map((field) => {
                    const formField = (() => {
                        switch (field.type) {
                            case "input":
                                return <Input />;
                            case "number":
                                return <Input type="number" />;
                            case "textarea":
                                return <Input.TextArea />;
                            case "email":
                                return <Input type="email" />;
                            case "select":
                                return (
                                    <Select>
                                        {field.options.map((option) => (
                                            <Select.Option key={option}>{option}</Select.Option>
                                        ))}
                                    </Select>
                                );
                            case "date":
                                return <DatePicker />;
                            case "radio":
                                return (
                                    <Radio.Group>
                                        {field.options.map((option) => (
                                            <Radio key={option} value={option}>
                                                {option}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                );
                            default:
                                return null;
                        }
                    })();

                    return (
                        <Form.Item
                            key={field._id}
                            label={field.label}
                            name={field.label}
                            rules={[
                                {
                                    required: field.isRequired,
                                    message: `Please enter ${field.label}`,
                                },
                            ]}
                        >
                            {formField}
                        </Form.Item>
                    );
                })
            ) : (
                <p>No data available</p>
            )}
        </>
    );
};

export default LoadForm;
