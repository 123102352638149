import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, message, Row,} from "antd";
import {BASE_URL} from "../utils";
import axios from "axios";
import LoadForm from "../utils/LoadForm";

const FormReader = () => {
    const [applicationId, setApplicationId] = useState(null);
    const [formData, setFormData] = useState([]);
    const [formDataId, setFormDataId] = useState();
    const [form] = Form.useForm();

    useEffect(() => {
        setApplicationId(localStorage.getItem("applicationId"));
    }, []);

    useEffect(() => {
        if (applicationId != null) {
            fetchApplicationForm(applicationId);
        }
    }, [applicationId]);

    const fetchApplicationForm = async (applicationId) => {
        try {
            const res = await fetch(`${BASE_URL}form-builders/${applicationId}`);
            const data = await res.json();
            if (!data.form.length) {
                message.info("No form found for this application!");
                return;
            }
            setFormData(data.form);
            setFormDataId(data.form[0]._id);
        } catch (error) {
            message.error("Something went wrong!");
        }
    };


    const handleFormSubmit = async (values) => {
        try {
            const formId = formDataId;
            const formValues = await axios.get(`${BASE_URL}forms/${formId}`)

            console.log("here: ", formValues)

            const payload = {
                applicationId,
                formId,
                values: values,
            }

            const res = await fetch(`${BASE_URL}forms`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
            const data = await res.json();

            if (data.status) {
                message.success("Form submitted successfully!");
            } else {
                message.error("Something went wrong!");
            }

        } catch (error) {
            message.error("Something went wrong!");
        } finally {
            form.resetFields();
        }
    };


    return (
        <Row>
            <Col span={12} offset={6}>
                <Card
                    title="Form Reader"
                    bordered
                    hoverable
                    style={{marginTop: "20px"}}
                    cover
                >
                    <Form
                        onFinish={handleFormSubmit}
                        layout='horizontal'
                        form={form}
                    >
                        <LoadForm formData={formData}/>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );

};

export default FormReader;